import placeholder from "../../images/placeholder.svg"

import logo1 from "../../images/memberslogo400x400/colored/Bal Bharti Manesar.png"
import logo2 from "../../images/memberslogo400x400/colored/Bal Bharti Pitampura.png"
import logo3 from "../../images/memberslogo400x400/colored/Chinmaya Vidyalaya.png"
import logo4 from "../../images/memberslogo400x400/colored/DPS Jaipur.png"
import logo5 from "../../images/memberslogo400x400/colored/DPS Nagpur.png"
import logo6 from "../../images/memberslogo400x400/colored/DPS Panipat.png"
import logo7 from "../../images/memberslogo400x400/colored/DPS Sector-45.png"
import logo8 from "../../images/memberslogo400x400/colored/DPS Sonepat.png"
import logo9 from "../../images/memberslogo400x400/colored/IPS Bhopal.png"
import logo10 from "../../images/memberslogo400x400/colored/KRM Greater Noida.png"
import logo11 from "../../images/memberslogo400x400/colored/KRM Vaishali.png"
import logo12 from "../../images/memberslogo400x400/colored/Lotus Valley.png"
import logo13 from "../../images/memberslogo400x400/colored/Mayoor School.png"
import logo14 from "../../images/memberslogo400x400/colored/NES R K Puram.png"
import logo15 from "../../images/memberslogo400x400/colored/Summer Field School.png"
import logo16 from "../../images/memberslogo400x400/colored/Titiksha.png"

// export const memberLogos = [
//   logo1,
//   logo2,
//   logo3,
//   logo4,
//   logo5,
//   logo6,
//   logo7,
//   logo8,
//   logo9,
//   logo10,
//   logo11,
//   logo12,
//   logo13,
//   logo14,
//   logo15,
//   logo16,
// ]

export const memberLogos = [
  { src: logo16, link: "http://titikshapublicschool.com/" },
  { src: logo6, link: "http://www.dpspanipatcity.in/" },
  { src: logo12, link: "https://www.lotusvalleygurgaon.com/" },
  { src: logo13, link: "https://www.mayoornoida.net/" },
  { src: logo15, link: "http://summerfields.co.in/" },
  { src: logo7, link: "http://www.dpsgurgaon.org/" },
  { src: logo11, link: "http://krmangalam.com/vaishali/" },
  { src: logo5, link: "http://dpsnagpur.edu.in/main.php" },
  { src: logo2, link: "https://bbpspp.balbharati.org/" },
  { src: logo8, link: "http://www.dps.in/?AspxAutoDetectCookieSupport=1" },
  { src: logo9, link: "http://www.internationalpublicschools.com/" },
  { src: logo3, link: "http://www.chinmayavvdelhi.ac.in/" },
  { src: logo4, link: "https://www.dpsjaipur.com/" },
  { src: logo14, link: "https://www.keralaschoolrkp.org/" },
  { src: logo10, link: "https://krmangalam.com/greaternoida/" },
  { src: logo1, link: "https://bbpsmn.balbharati.org/" },
]
