import React from "react"
import "./style.scss"
import { Link } from "gatsby"

export default function About() {
  return (
    <div className="about" id="about">
      <div className="row about__row">
        <div className="col col-12 col-lg-4">
          <h2 className="about__heading">
            About <div style={{ display: "inline-block" }}>School LIVE</div>
          </h2>
          {/* <div className="meettheteam--desktop">
            <Link className="button-primary mt-sm" to="/team">
              Meet the Team
            </Link>
          </div> */}
          <br />
        </div>
        <div className="col col-12 col-lg-8">
          <p className="about__text">
            Established in 2014, School LIVE is a monthly magazine, committed to
            providing interesting and useful content that is for the students,
            and with the students. By delivering a curation of content in a
            variety of categories, including international university
            admissions, Indian higher education, counselling psychology,
            innovative education, School LIVE is dedicated to inculcating
            regular reading habits and corresponding critical thinking skills
            amongst the youth, while providing a secure, direct platform with
            student-friendly content and associations.
            <br /> <br />
            School LIVE is approved and supported by Principals, Teachers and
            Parents all over India, and is both parent and student-oriented.
            <br />
            <br />
            School LIVE goes to over 500 schools, pan India, and has a total
            readership of more than 1 lakh, including school students and their
            parents.
          </p>

          {/* <div className="meettheteam--mobile">
            <Link className="button-primary mt-sm" to="/team">
              Meet the Team
            </Link>
          </div> */}
        </div>
      </div>
      <br />
    </div>
  )
}
