import React from "react"
import "./style.scss"
import InsidePreview from "../insidepreview"
// import insideImage from "../../images/insidecovers/february.jpg"
import { Link } from "gatsby"
import { useState } from "react"

import feb2019Preview from "../../images/insidecovers/february.jpg"
import sep2019Preview from "../../images/insidecovers/sep-inside.png"
import oct2019Preview from "../../images/insidecovers/OCT-ISSUE-INSIDE.png"
import dec2019Preview from "../../images/insidecovers/december2.jpg"
import jan2020Preview from "../../images/insidecovers/january.jpg"
import amitabhPreview from "../../images/insidecovers/Oct-Amitabh.jpg"
import nov2019Preview from "../../images/insidecovers/inside-nov-2019.png"

export default function FeaturedIssues() {
  const [showPreview, setShowPreview] = useState(false)
  const [currentPreview, setCurrentPreview] = useState(null)

  const togglePreview = () => {
    setShowPreview(!showPreview)
  }

  const setPreview = preview => {
    setCurrentPreview(preview)
    togglePreview()
  }

  return (
    <div className="featured-issues">
      <hr className="hr" />
      <h2 className="heading-primary">Featured Issues</h2>
      <p className="featured-issues__text">
        We are proud to have featured people of eminence and leadership in their
        respective fields, including Amitabh Bachan, Col. Rajyavardhan Singh
        Rathore, Amish Tripathi, Chetan Bhagat, Smriti Irani, Sania Mirza, Saina
        Nehwal, Anoushka Shankar, and Rajkumar Hirani, and cherish guest
        articles from Mr. Rajdeep Sardesai, Yashwant Deshmukh, Neelesh Misra,
        Anshul Tewari, to name a few.
      </p>
      <div className="row featured-issues__row">
        <div className="col col-12 col-lg-5">
          <div className="row">
            <div className="col col-6 col-md-4 col-lg-6">
              <div
                className="featured-issues__card featured-issues__card--feb2019"
                onClick={() => setPreview(feb2019Preview)}
              ></div>
            </div>
            <div className="col col-6 col-md-4 col-lg-6">
              <div
                className="featured-issues__card featured-issues__card--sep2019"
                onClick={() => setPreview(sep2019Preview)}
              ></div>
            </div>
            <div className="col col-6 col-md-4 col-lg-6">
              <div
                className="featured-issues__card featured-issues__card--oct2019"
                onClick={() => setPreview(oct2019Preview)}
              ></div>
            </div>
            <div className="col col-6 col-md-4 col-lg-6">
              <div
                className="featured-issues__card featured-issues__card--nov2019"
                onClick={() => setPreview(nov2019Preview)}
              ></div>
            </div>
          </div>
        </div>
        <div className="col col-12 col-lg-7">
          <div className="row ">
            <div className="col col-12 col-lg-8">
              <div
                className="featured-issues__card featured-issues__card--fullheight"
                onClick={() => setPreview(amitabhPreview)}
              ></div>
            </div>
            <div className="col col-12 col-lg-4">
              <div className="row">
                <div className="col col-6 col-lg-12">
                  <div
                    className="featured-issues__card featured-issues__card--dec2019"
                    onClick={() => setPreview(dec2019Preview)}
                  ></div>
                </div>
                <div className="col col-6 col-lg-12">
                  <div
                    className="featured-issues__card featured-issues__card--jan2020"
                    onClick={() => setPreview(jan2020Preview)}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Link className="button-primary" to="/archives">
        More
      </Link>
      <InsidePreview
        show={showPreview}
        onClickClose={togglePreview}
        src={currentPreview}
      />
    </div>
  )
}
