import React, { useEffect, useState } from "react"
import Navbar from "../navbar"
import "./style.scss"
import figure3 from "../../images/schoolliveartwork/BOOK1-01.png"
import figure2 from "../../images/schoolliveartwork/BOOK2-01.png"
import figure1 from "../../images/schoolliveartwork/BOOK3-01.png"
import { Link } from "gatsby"

export default function Hero() {
  const [transformValue, setTransformValue] = useState(0)

  useEffect(() => {
    function handleScroll() {
      let yPos = window.scrollY

      if (timer !== null) {
        clearTimeout(timer)
      }
      timer = setTimeout(function () {
        if (yPos < 100) {
          setTransformValue(yPos)
        }
      }, 0)
    }

    var timer = null
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <div className="hero">
      <Navbar />
      <div className="hero__circlesmall"></div>
      <div className="hero__circlefront"></div>
      <div className="hero__circleback"></div>

      <div className="hero__figures">
        <img className="hero__figure1" src={figure1} />
        <img className="hero__figure2" src={figure2} />
        <img className="hero__figure3" src={figure3} />
        <div
          className="hero__figures__small-el"
          style={{
            transform: `translateX(-${transformValue}px)`,
            transition: "1s",
          }}
        ></div>
        <div
          className="hero__figures__small-el2"
          style={{
            transform: `translateX(-${transformValue}px)`,
            transition: "2s",
          }}
        ></div>
        <div
          className="hero__figures__small-el3"
          style={{
            transform: `translateX(${transformValue}px)`,
            transition: "2.5s",
          }}
        ></div>
      </div>

      <h1 className="hero__heading">
        The Only Magazine for School Students & their Parents
      </h1>

      <div className="hero__buttons">
        <Link className="button-primary">Subscriptions</Link>
        <Link className="button-primary" to="/archives">
          Explore
        </Link>
      </div>
    </div>
  )
}
