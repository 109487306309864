import logo1 from "../../images/partnersassociations400x400/Aakash Institute.png"
import logo2 from "../../images/partnersassociations400x400/BMC Masale.png"
import logo3 from "../../images/partnersassociations400x400/Camlin.png"
import logo4 from "../../images/partnersassociations400x400/Career Launcher.png"
import logo5 from "../../images/partnersassociations400x400/Cox & Kings.png"
import logo6 from "../../images/partnersassociations400x400/DPS.png"
import logo7 from "../../images/partnersassociations400x400/Durckback.png"
import logo8 from "../../images/partnersassociations400x400/EL.png"
import logo9 from "../../images/partnersassociations400x400/Full Marks.png"
import logo10 from "../../images/partnersassociations400x400/Fun City.png"
import logo11 from "../../images/partnersassociations400x400/Givo.png"
import logo12 from "../../images/partnersassociations400x400/Indian Oil.png"
import logo13 from "../../images/partnersassociations400x400/Indian School of Hospitality.png"
import logo14 from "../../images/partnersassociations400x400/KR Mangalam.png"
import logo15 from "../../images/partnersassociations400x400/Orange Education.png"
import logo16 from "../../images/partnersassociations400x400/PFC.png"
import logo17 from "../../images/partnersassociations400x400/Pizza Hut.png"
import logo18 from "../../images/partnersassociations400x400/Su-kam.png"
import logo19 from "../../images/partnersassociations400x400/USK Foundation.png"

export const clients = [
  {
    src: logo1,
    link: "https://www.aakash.ac.in/",
    alt: "Akash Institute Logo",
  },
  {
    src: logo2,
    link: "https://bawamasalacompany.com/",
    alt: "BMC Masale Logo",
  },
  { src: logo3, link: "https://www.kokuyocamlin.com/", alt: "Camlin Logo" },
  {
    src: logo4,
    link: "https://www.careerlauncher.com/",
    alt: "Career Launcher Logo",
  },
  { src: logo5, link: "https://www.coxandkings.com/", alt: "Cox & Kings Logo" },
  { src: logo6, link: "http://dpsfamily.org/", alt: "DPS Logo" },
  { src: logo7, link: "http://duckback.in/", alt: "Durckback Logo" },
  { src: logo8, link: "https://www.elcomponics.com/", alt: "EL Logo" },
  { src: logo9, link: "https://fullmarks.org/", alt: "Full Marks Logo" },
  { src: logo10, link: "https://funcity.in/", alt: "Fun City Logo" },
  { src: logo11, link: "https://www.givo.com/", alt: "Givo Logo" },
  { src: logo12, link: "https://iocl.com/", alt: "Indian Oil Logo" },
  {
    src: logo13,
    link: "https://www.ish.edu.in/",
    alt: "Indian Institute of Hospitality Logo",
  },
  { src: logo14, link: "https://krmangalam.edu.in/", alt: "KR Mangalam Logo" },
  {
    src: logo15,
    link: "https://www.orangeeducation.in/",
    alt: "Orange Education Logo",
  },
  { src: logo16, link: "https://www.pfcindia.com/", alt: "PFC Logo" },
  { src: logo17, link: "https://www.pizzahut.co.in/", alt: "Pizza Hut Logo" },
  { src: logo18, link: "http://www.su-kam.com/", alt: "Su-kam Logo" },
  {
    src: logo19,
    link: "",
    alt: "USK Foundation Logo",
  },
]
