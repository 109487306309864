import React, { useState } from "react"
import "./style.scss"
import { clients } from "./data"
// import Slider from "react-slick"
import { Link } from "gatsby"

export default function Advertise() {
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // }
  const [initialValue, setInitialValue] = useState(0)
  const [finalValue, setFinalValue] = useState(8)

  function next() {
    let ini = initialValue
    let fin = finalValue
    if (ini === 8 && fin === 16) {
      ini = 16
      fin = 19
    } else if (ini === 16 && fin === 19) {
      ini = 0
      fin = 8
    } else {
      ini = initialValue + 8
      fin = finalValue + 8
    }
    setInitialValue(ini)
    setFinalValue(fin)
  }

  const clientJSX = clients
    .map((client, i) => (
      <div key={i} className="col col-4 col-lg-3">
        <a href={client.link} target="__blank" rel="noreferrer">
          <img
            className="advertise__clients__img"
            alt={client.alt}
            src={client.src}
          ></img>
        </a>
      </div>
    ))
    .slice(initialValue, finalValue)
  return (
    <div className="advertise" id="advertising">
      <hr className="hr" />
      <h2 className="heading-primary">Advertising with School LIVE</h2>

      <div className="row advertise__row">
        <div className="col col-12 col-lg-6">
          <br />
          School LIVE Magazine reaches over 20,000 identifiable readers in the
          niche target groups of students from Grade VI to Grade XII, and their
          parents, in the age bracket of 35 to 45.
          <br />
          <br /> Bull's Eye, our advertising rate card offers special,
          innovative association models, and with a commitment and passion for
          education, School LIVE provides tremendous value and special
          privileges from student-friendly brands to our young readers, with the
          delivery of each monthly edition.
          <br />
          <br />
          <a
            className="button-primary"
            href="http://school-live.com/assets/docs/Advertisement.pdf"
            target="__blank"
          >
            Rate Card
          </a>
          <Link
            to="/connect"
            className="button-primary"
            style={{ marginLeft: "20px" }}
          >
            Enquire Now
          </Link>
        </div>

        <div className="col col-12 col-lg-6">
          <div className="advertise__clients">
            <div className="row">{clientJSX}</div>
            <button className="advertise__clients__nextbutton" onClick={next}>
              &#8680;
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
